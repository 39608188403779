
  import Vue from 'vue';
  import * as api from '@/api';

  export default Vue.extend({
    name: 'RoleList',
    data() {
      return {
        sorting: 'time',
        roles: [] as api.Role[],
      };
    },
    async created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '角色权限',
          path: 'role',
        },
      ]);
      await this.fetchData();
      this.handleSorting('time');
    },
    methods: {
      async fetchData() {
        const res = await api.getRoleList();
        if (!api.error(res)) {
          this.roles = res.data;
        }
      },
      handleSorting(command) {
        this.sorting = command;
        if (command === 'time') {
          this.roles.sort((a, b) => Number(new Date(b.creationTime)) - Number(new Date(a.creationTime)));
        } else if (command === 'userCount') {
          this.roles.sort((a, b) => b.userCount - a.userCount);
        }
      },
      goToCreate() {
        this.$store.commit('editRole', null);
        this.$router.push({ name: 'RoleCreate' });
      },
      editRole(role: api.Role) {
        this.$store.commit('editRole', role);
        this.$router.push({ name: 'RoleEdit' });
      },
      async removeRole(role: api.Role) {
        this.$confirm('删除角色不可恢复', '你确定要删除吗？', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定删除',
          confirmButtonClass: 'bg-danger',
          cancelButtonText: '取消',
          iconClass: 'el-icon-question',
        }).then(async () => {
          const res = await api.removeRole({ id: role.id });
          if (!api.error(res)) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.fetchData();
          } else {
            this.$message({
              type: 'error',
              message: res.error.message,
            });
          }
        });
      },
      async toggleRoleEnable(role: api.Role, value) {
        const res = value ? await api.roleEnable([role.id]) : await api.roleDisable([role.id]);
        if (!api.error(res)) {
          role.enabled = value;
        } else {
          this.$message({
            type: 'error',
            message: res.error.message,
          });
        }
      },
    },
  });
